<template>
  <label
    class="vz-label text-caption-bold"
    :for="id"
    :class="classes"
    :style="styles"
  >
    <slot >{{label}}</slot>
    <vz-tooltip-info v-if="tooltipText" :text="tooltipText" />
  </label>
</template>

<script setup lang="ts">
import { KeyValueObject } from '~/types/types'
import {IconVariants, UIKitVariants} from "~/types/variants";

const props = defineProps({
  required: {
    type: Boolean,
    default: false
  },
  variant: {
    type: String as PropType<UIKitVariants | IconVariants>,
    default: ''
  },
    id: {
        type: String,
        default: '',
    },
    label: {
        type: String,
        default: ''
    },
    tooltipText: {
        type: String,
        default: '',
    },
    hasError: {
        type: Boolean,
        default: false,
    },
    withoutDot: {
        type: Boolean,
        default: false,
    },
    size: {
        type: Number,
        default: undefined,
    },
})

    const classes = computed((): KeyValueObject => {
      return {
        [props.variant]: true,
        'color-error': props.hasError,
        required: props.required,
        'without-dot': props.withoutDot
      }
    })

    const styles = computed(() => {
      return {
        'font-size': props.size ? `${props.size}px` : null,
      }
    })
</script>
